<template>
  <b-modal :active="true" :can-cancel="false" id="confirmationModal">
    <div
      class="modal_confirmation"
      v-bind:style="getBackgroundColor(design.backgroundColorReservationPopUp, 'back')"
    >
      <div
        :style="{'background-color': design.checkColorReservationPopUp}"
        class="icon_confirmation"
      >
        <i class="icons icon_check"></i>
      </div>
      <div
        :style="{'background-image': 'url(' + vendor.lightLogoURL + ')'}"
        class="cont_logo_restaurant"
      ></div>
      <div>
        <div
          :style="{'color' : design.checkColorReservationPopUp}"
          class="title_modal ffr fw700"
          v-if="!isAndresDc()"
        >¡{{$t('congratulations')}}!</div>
        <div
          :style="{'color' : design.checkColorReservationPopUp}"
          class="title_modal ffr fw700"
          v-if="isAndresDc()"
        >¡La mesa está lista y el corazón contento por tu llegada!</div>
        <div
          :style="getBackgroundColor(design.textColorReservationPopUp, 'p')"
          class="subtitle_modal ffr fw300"
          v-if="!reservationData.isFree && reservationData.isWarranty === 0"
        >
          <b>{{reservationData.name}}</b>
          {{ $t('preCompraSucces')}}
        </div>
        <div
          :style="getBackgroundColor(design.textColorReservationPopUp, 'p')"
          class="subtitle_modal ffr fw300"
          v-else
        >
          <b>{{reservationData.name}}</b>
          {{ (!isAndresDc()) ? (reservationData.isRequest) ? $t('reservationCompleteRequest') : $t('reservationComplete') : 'ya está confirmada tu reserva.'}}
        </div>
        <div class="title_info">
          <div>
            <i
              :style="{'background' : design.textColorReservationPopUp}"
              class="icons icon_reservations"
              v-if="reservationData.isFree"
            ></i>
            <i
              :style="{'background' : design.textColorReservationPopUp}"
              class="icons icon_precompro"
              v-else
            ></i>
          </div>
          <b
            :style="{'color' : design.textColorReservationPopUp}"
            class="ffr bolty"
            v-if="reservationData.isFree"
          >{{ (reservationData.isRequest) ? $t('informationReservationFreeRequest') : $t('informationReservationFree') }}</b>
          <b
            :style="{'color' : design.textColorReservationPopUp}"
            class="ffr bolty"
            v-if="!reservationData.isFree && reservationData.isWarranty === 0"
          >{{ $t('informationReservationPayment') }}</b>
          <b
            :style="{'color' : design.textColorReservationPopUp}"
            class="ffr bolty"
            v-if="!reservationData.isFree && reservationData.isWarranty === 1"
          >{{ $t('informationReservationFree') }}</b>
        </div>
        <div class="info_reserve columns">
          <div class="main_info column is-3 is-2-mobile p-0">
            <div class="cont_icon">
              <i
                :style="{'background' : design.textColorReservationPopUp}"
                class="icons icon_people"
              ></i>
            </div>
            <span
              :style="{'color' : design.textColorReservationPopUp}"
              class="text_res people_res ffr bolty"
            >{{ reservationData.people }}</span>
          </div>
          <div class="main_info column is-5 p-0">
            <div class="cont_icon">
              <i
                :style="{'background' : design.textColorReservationPopUp}"
                class="icons icon_calendar"
              ></i>
            </div>
            <span
              :style="{'color' : design.textColorReservationPopUp}"
              class="text_res date_res ffr bolty"
            >{{ parseInt(reservationData.dateTime) | moment('timezone', vendorTimezone, 'MMMM DD') }}</span>
          </div>
          <div class="main_info column is-4 is-5-mobile p-0">
            <div class="cont_icon">
              <i
                :style="{'background' : design.textColorReservationPopUp}"
                class="icons icon_clock"
              ></i>
            </div>
            <span
              :style="{'color' : design.textColorReservationPopUp}"
              class="text_res hour_res ffr bolty"
            >{{ parseInt(reservationData.dateTime) | moment('timezone', vendorTimezone, 'hh:mm a') }}</span>
          </div>
        </div>
        <div class="text_description" v-if="!reservationData.isRequest">
          <p
            id="popupText"
            v-bind:style="getBackgroundColor(design.textColorReservationPopUp, 'p')"
            v-html="replaceParamsInMessage(params.popupReservationMsg)"
          ></p>
        </div>
        <div class="text_description" v-if="reservationData.isWarranty === 1">
          * <span :style="{'color' : design.textColorReservationPopUp}">Para garantizar tu reserva se realiza una autorización como garantía por persona.
          En caso de no asistir o no cancelar con mínimo {{ params.anticipationWarranty }} horas de anticipación, este será debitado de la tarjeta.</span>
        </div>
        <div
          :style="{justifyContent: 'center'}"
          class="btns_modal"
        >
          <button
            :class="{'id_full':parseInt(idUser) === 13744 || reservationData.isRequest}"
            @click="goClose()"
            class="btn_modal ffr fw500"
            type="button"
            v-bind:style="
              getBackgroundColor(
              design.backgroundColorReservationButtonPopUp,
              'btn',
              design.textColorReservationButtonPopUp
              )"
            v-if="parseInt(idUser) === 13744 || reservationData.isRequest"
          >{{$t('finish')}}</button>
          <button
            :class="{'full-w':!reservationData.isFree }"
            @click="goAccount()"
            class="btn_modal ffr fw500"
            data-dismiss="modal"
            type="button"
            v-bind:style="
              getBackgroundColor(
              design.backgroundColorReservationButtonPopUp,
              'btn',
              design.textColorReservationButtonPopUp
              )"
            v-if="parseInt(idUser) !== 13744 && !reservationData.isRequest"
          >{{$t('seeBooking')}}</button>
          <button
            :class="{'full-w':reservationData.isFree }"
            @click="goCompanion(reservationData.infoId)"
            class="btn_modal ffr fw500 ml20"
            data-dismiss="modal"
            type="button"
            v-bind:style="
              getBackgroundColor(
              design.backgroundColorReservationButtonPopUp,
              'btn',
              design.textColorReservationButtonPopUp
              )"
            v-if="params.activeCompanion && reservationData.people > 1 && !reservationData.isRequest"
          >{{$t('diners2')}}</button>
        </div>
        <div class="cont_contact ffr fw300" v-if="vendor.phone">
          {{ $t('informationReservationContact') }}
          <strong>{{ vendor.phone }}</strong>
        </div>
        <div class="logo_precompro" v-show="!embedSystem"></div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import authTypes from '@/store/types/auth';
import _ from 'lodash';

export default {
  props: ['reservationData'],
  data () {
    return {
      show: true
    };
  },
  mounted () {
    this.getStrongClass();
  },
  computed: {
    ...mapGetters({
      showModal: whiteLabelTypes.getters.showModalReservationConfirm
    })
  },
  methods: {
    ...mapActions({
      logout: authTypes.actions.logout
    }),
    replaceParamsInMessage (message) {
      const newMessage = message.replace(/__TIEMPOGUARDARESERVA__/g, this.params.anticipationUserTime);
      return newMessage;
    },
    getStrongClass () {
      let style = document.createElement('style');
      style.innerHTML = `strong { color: ${this.design.textColorReservationPopUp}; }`;
      document.getElementsByTagName('head')[0].appendChild(style);
    },
    getRgb (color) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    getBackgroundColor (color, type = null, color2 = null) {
      if (type === 'back') {
        const rgb = this.getRgb(color);
        return {
          background: `rgba(${rgb['r']}, ${rgb['g']}, ${rgb['b']}, 0.9)`
        };
      }
      if (type === 'p') {
        return {
          color: `${color}!important`
        };
      }
      if (type === 'div') {
        if (color2 != null) return { color: `${color}!important` };
        return {
          color: `${color}!important`,
          'border-right': `1px solid ${color}`
        };
      }
      if (type === 'text') {
        if (color2 != null) return { color: `${color}!important` };
        return {
          color: `${color}!important`,
          'border-top': `1px solid ${color}`
        };
      }
      if (type === 'btn') {
        return {
          'background-color': color,
          color: color2
        };
      }
      return {
        'background-color': color
      };
    },
    goAccount () {
      window.localStorage.removeItem('_reservation');
      window.localStorage.removeItem('_reservationOk');
      window.localStorage.removeItem('_reservation_time');
      window.localStorage.removeItem('_reservation_more_time');
      window.localStorage.removeItem('_oldPayExperience');
      window.localStorage.removeItem('_oldPayExperienceFull');
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalReservationConfirm
      );
      if (this.user === undefined) {
        if (this.embedSystem) {
          window.location.href = '/?embed=true';
        } else {
          window.location.href = '/';
        }
      }
      window.location.href = '/account';
    },
    goClose () {
      window.localStorage.removeItem('_reservation');
      window.localStorage.removeItem('_reservation_link');
      window.localStorage.removeItem('_reservationOk');
      window.localStorage.removeItem('_reservation_time');
      window.localStorage.removeItem('_reservation_more_time');
      window.localStorage.removeItem('_oldPayExperience');
      window.localStorage.removeItem('_oldPayExperienceFull');
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalReservationConfirm
      );
      this.$store.commit(reservationTypes.mutations.setSendReservation, false);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, null);
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
      this.$store.commit(reservationTypes.mutations.setSelectedZone, false);
      this.$store.commit(reservationTypes.mutations.setSelectedTable, null);
      if (parseInt(this.idUser) === 13744) {
        this.logout({ id: 13744 }).then(() => {
          window.localStorage.removeItem('_user');
          if (this.embedSystem) {
            window.location.href = '/?embed=true';
          } else {
            window.location.href = '/';
          }
        });
      } else {
        if (this.embedSystem) {
          window.location.href = '/?embed=true';
        } else {
          window.location.href = '/';
        }
      }
    },
    goCompanion (id) {
      window.localStorage.removeItem('_reservation');
      window.localStorage.removeItem('_reservation_link');
      window.localStorage.removeItem('_reservationOk');
      window.localStorage.removeItem('_reservation_time');
      window.localStorage.removeItem('_reservation_more_time');
      window.localStorage.removeItem('_oldPayExperience');
      window.localStorage.removeItem('_oldPayExperienceFull');
      this.$store.commit(
        whiteLabelTypes.mutations.setShowModalReservationConfirm
      );
      if (this.idUser === 13744) {
        this.logout({ id: 13744 }).then(() => {
          window.localStorage.removeItem('_user');
          window.location.href = '/reservation?data=' + id;
        });
      } else {
        window.location.href = '/reservation?data=' + id;
      }
    },
    timeMsg (time, hour = null, days = []) {
      let tim = this.$moment(parseInt(time)).format('HH:mm');
      let day = parseInt(this.$moment(parseInt(time)).format('d'));
      if (hour != null && days.length === 0) {
        if (tim >= hour) {
          return true;
        } else {
          return false;
        }
      }
      if (hour != null && days.length > 0) {
        if (_.indexOf(days, day) > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
};
</script>
<style lang="scss" >
@import "@/assets/styles/Confirmation/confirmationModal.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
#confirmationModal {
  strong {
    font-weight: 400;
  }
  .bolty {
    font-weight: 400;
  }
  .subtitle_modal {
    max-width: 62%;
    margin: auto;
    min-width: 300px;
    margin-bottom: 15px !important;
    b {
      font-weight: 400;
    }
  }
}
</style>
